.content {
    min-height: 90vh;
    padding-bottom: 0;

    :global(.modal-body) {
        padding: 0;
    }

    :global(.modal-header) {
        padding: 0;
    }

    :global(.modal-header .close) {
        z-index: 99;
        margin-right: 0px;
    }

    :global(.modal-footer) {
        display: none;
    }

    iframe::-webkit-scrollbar {
        width: var(--sn-space-1);
        height: var(--sn-space-1);
    }

    iframe::-webkit-scrollbar-track {
        background-color: var(--sn-grey-50);
    }

    iframe::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: var(--sn-grey-100);
    }
}
